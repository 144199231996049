import { GrowthBook } from "@growthbook/growthbook";
import Cookies from "js-cookie";

window.dataLayer = window.dataLayer || [];

function getGrowthbookDefaultAttributes() {
  return {
    url: location.href,
    path: location.pathname,
    host: location.host,
    query: location.search,
    pageTitle: document && document.title,
    referrer: document.referrer,
    utm_medium: Cookies.get("utm_medium"),
    utm_campaign: Cookies.get("utm_campaign"),
    utm_source: Cookies.get("utm_source"),
    utm_content: Cookies.get("utm_content"),
    utm_term: Cookies.get("utm_term"),
  }
}

async function init() {
  if (Cookies.get("functional_cookie_consent") !== "1") return;
  const id = Cookies.get("_ga");

  const growthbook = new GrowthBook({
    apiHost: "https://cdn.growthbook.io",
    clientKey: "sdk-DKRVtx4XyiFcRPr",
    enableDevMode: true,
    trackingCallback: (experiment, result) => {
      window.dataLayer.push({
        event: 'gb_experiment_viewed',
        experimentId: experiment.key,
        variationId: result.key
      });
    },
    attributes: {
      id,
      ...getGrowthbookDefaultAttributes(),
    },
  });

  // Wait for features to be available
  await growthbook.init({ streaming: true });

  if (!id) checkGtagAndRunCallback(growthbook);
}

let checkCookieCount = 0;
function checkGtagAndRunCallback(growthbook) {
  checkCookieCount++;
  const id = Cookies.get("_ga");
  if (id) {
    growthbook.setAttributes({
      id,
      ...getGrowthbookDefaultAttributes()
    });
  } else if (checkCookieCount < 20) {
    setTimeout(() => checkGtagAndRunCallback(growthbook), 100);
  }
}

init();
